import type { Dayjs } from "dayjs";
import type { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType";
import type { WorkPacketView } from "src/pages/UserDashboard/WorkPackets/WorkPacketView";

export enum RecoveryStream {
  All = "All",
  InventoryShortages = "Inventory shortages",
  PriceClaims = "Price Claims",
  Accruals = "Accruals",
  Chargebacks = "Chargebacks",
}

export enum StageName {
  First = "FIRST",
  Second = "SECOND",
  Third = "THIRD",
}

export enum StagefulAction {
  Identified = "IDENTIFIED",
  DisputeFailed = "DISPUTE_FAILED",
  DisputeCreated = "DISPUTE_CREATED",
  DisputeInReview = "DISPUTE_IN_REVIEW",
  DisputeApproved = "DISPUTE_APPROVED",
  DisputePartiallyApproved = "DISPUTE_PARTIALLY_APPROVED",
  DisputeDenied = "DISPUTE_DENIED",
  DisputePaid = "DISPUTE_PAID",
  DisputeInvoiced = "DISPUTE_INVOICED",
}

export enum StagelessAction {
  Junk = "JUNK",
  Duplicate = "DUPLICATE",
  Expired = "EXPIRED",
  NotApplicable = "NOT_APPLICABLE",
}

export type FullStagefulAction = `${StageName}_${StagefulAction}`;

export type WorkPacketActionName = FullStagefulAction | `${StagelessAction}`;

export interface WorkPacketAction {
  value: WorkPacketActionName;
  title: string;
  category: string;
  color: string;
  onlyAdminCanSet?: boolean;
}

// Each enum value corresponds to the filter key expected by the server
export enum WorkPacketFilter {
  AsinId = "asinId",
  RecoveryStream = "recoveryStream",
  RecoveryStreamType = "recoveryStreamType",
  RecoveryStreamSubtype1 = "recoveryStreamSubtype1",
  RecoveryStreamSubtype2 = "recoveryStreamSubtype2",
  PacketStage = "packetStage",
  StoreName = "storeName",
  VcPoId = "vcPoId",
  CurrentAction = "currentAction",
  CurrentPacketOwner = "currentPacketOwner",
  VendorName = "vendorName",
  RedFlags = "redFlags",
  IssueId = "issueId",
  ChargebackCreatedAt = "chargebackCreatedAt",
  DisputeCreatedAt = "disputeCreatedAt",
  ManualFilingUser = "manualFilingUser",
  FreightTerms = "freightTerms",
  InboundShipmentDeliveryIsdId = "inboundShipmentDeliveryIsdId",

  // accrual packets only:
  RecoveryStreamSubtype = "recoveryStreamSubtype",
  ObarCreatedDate = "createdDateObar",
  AgreementId = "agreementId",
  InvoiceDate = "invoiceDate",
  InvoiceNumber = "invoiceNumber",
  OriginalDisputeId = "originalDisputedId",
  ConsolidatedDisputeId = "consolidatedDisputedId",
  DisputeStatus = "disputeStatus",
  WithNotes = "withNotes",

  // pseudo-filters
  View = "view",
  PreCase = "preCase",
}

export interface WorkPacketFilters {
  [WorkPacketFilter.RecoveryStream]: string;
  [WorkPacketFilter.RecoveryStreamType]: string;
  [WorkPacketFilter.RecoveryStreamSubtype1]: string;
  [WorkPacketFilter.RecoveryStreamSubtype2]: string;
  [WorkPacketFilter.PacketStage]: string;
  [WorkPacketFilter.StoreName]: string;
  [WorkPacketFilter.CurrentAction]: string;
  [WorkPacketFilter.CurrentPacketOwner]: string;
  [WorkPacketFilter.VendorName]: string;
  [WorkPacketFilter.RedFlags]: boolean;
  [WorkPacketFilter.ManualFilingUser]: string;
  [WorkPacketFilter.DisputeCreatedAt]: string;

  // pseudo-filters:
  [WorkPacketFilter.View]?: WorkPacketView;
  [WorkPacketFilter.PreCase]?: boolean;

  // chargeback packets only:
  [WorkPacketFilter.AsinId]: string[];
  [WorkPacketFilter.VcPoId]: string[];
  [WorkPacketFilter.IssueId]: string[];
  [WorkPacketFilter.ChargebackCreatedAt]: string;
  [WorkPacketFilter.FreightTerms]: string;
  [WorkPacketFilter.InboundShipmentDeliveryIsdId]: string[];

  // accrual packets only:
  [WorkPacketFilter.RecoveryStreamSubtype]: string;
  [WorkPacketFilter.ObarCreatedDate]: string[];
  [WorkPacketFilter.AgreementId]: string;
  [WorkPacketFilter.InvoiceDate]: string[];
  [WorkPacketFilter.InvoiceNumber]: string;
  [WorkPacketFilter.OriginalDisputeId]: string;
  [WorkPacketFilter.ConsolidatedDisputeId]: string;
  [WorkPacketFilter.DisputeStatus]: string;
  [WorkPacketFilter.WithNotes]: boolean;
}

export enum WorkPacketMetric {
  TotalStores = "Total Stores with Open Work Packets",
  OpenWorkPackets = "Open Work Packets",
  NewPackets = "New Packets",
  AverageTimePacketOpen = "Avg. time that packet is open",
  AverageTimeUnassigned = "Avg. time that packets have been unassigned",
  TotalPacketsAssigned = "Total Packets Assigned",
  PacketsWithRedFlags = "Packets with Red Flags",
  AvgTimePacketOpen = "Avg. time that packet is open",
}

export interface WorkPacketOwner {
  id: string;
  avatar: string;
  title: string;
}

export const stageNames = Array.from(Object.values(StageName));

/** Base interface for work packet used in Grid */
export interface WorkPacketBase {
  createdAt: string;
  workPacketType: WorkPacketType;
  packetId: string;
  vendorName: string;
  storeName: string;
  currentPacketOwner: WorkPacketOwner;
  currentAction: WorkPacketActionName;
  recoveryStream: RecoveryStream;
  packetStage: string;
  manualFilingUser: string;
  isValidWorkPacket: boolean;
  vendorId: string;
  storeId: number;
  packetDate: Dayjs;
  recoveryStreamServer: ServerStream;
  recoveryStreamActivationDate: Dayjs;
  vcFilingUser: string;
  notes: string;
  techUser: string;
  disputeByDate?: string;
  marketplaceCountry: string;
  modifiedAt: string;
  lastModifiedBy: WorkPacketOwner;
  closedDate: string;
  disputeApprovedAmount: string;
  evidenceType: string[];
  evidenceAttachment: string;

  /*
   * HACK(daniel): fake properties for the purpose of displaying custom work packet grid columns
   * that are not associated with any object property
   */
  _details: undefined;
}

export interface ChargebackWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.Chargebacks;
  vcPoId: string;
  asinId: string;
  chargebackIssueId: string;
  financialCharge: string;
  reversedAmount: string;
  vendorCode: string;
  chargebackCreateDate: string;
  techUser: string;
  recoveryStreamSubtype: string;
  recoveryStreamSubtype1: string;
  recoveryStreamSubtype2: string;
  firstDisputeCreatedAt?: string;
  secondDisputeCreatedAt?: string;
  calculatedDisputeByDate?: Dayjs | string | undefined;
  freightTerms: string;
  inboundShipmentDeliveryIsdId: string;
}

export interface ShortageWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.InventoryShortages;
  disputeId: string;
  disputeAmount: string;
  vcParentInvoiceId: string;
  vcDisputedInvoiceId: string;
  invoiceDate: string;
  invoiceDueDate: string;
  vcPayeeCode: string;
  shortageLag: string;
  disputeCreatedAt: string;
  disputeResolvedAt: string;
  disputePaymentId: string;
  disputePaymentAmount: string;
  disputePaymentDate: string;
}

export interface AccrualDispute {
  id: string;
  disputeAmount: string;
  disputeDate: string;
  originalDisputeId: string;
  consolidatedDisputeId: string;
  disputeStatus: string;
  invoiceStatus: string;

  // resolution
  approvedAmount: string;
  disputeResolutionDate: string;
  paidAmount: string;
  reversalInvoiceNumber: string;
}

export interface AccrualRemittance {
  paidAmount: string;
  reversalInvoiceNumber: string;
  paymentId: string;
  paymentDate: string;
}

export interface AccrualWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.Accruals;
  obarOption: string;
  disputes: AccrualDispute[];
  remittances: AccrualRemittance[];
  subType: string;
  agreementId: string;
  invoiceId: string;
  invoiceDate: string;
  currency: string;
  invoiceAmount: string;
  obarSuggestedDisputeAmount: string;
  sumOfAllReversalPayments: string;
  recoveryRate: string;

  // pseudo-properties for custom grid columns
  _generateObar?: undefined;
}

export type WorkPacket = ChargebackWorkPacket | ShortageWorkPacket | AccrualWorkPacket;

export interface ServerChargebackResource {
  ASIN_ID: string;
  CHARGEBACK_ISSUE_ID: string;
  CHARGE_INVOICE_NUMBER: string;
  CREATE_DATE: string;
  FINANCIAL_CHARGE: string;
  ISSUE_SUB_TYPE_DESC: string;
  NOTES: string;
  PRODUCT_NAME: string;
  RECOVERY_STREAM_SUB_TYPE: string;
  REVERSAL_INVOICE: string;
  STATUS: string;
  STORE_NAME: string;
  VC_PO_ID: string;
  VENDOR_CODE: string;
  VENDOR_NAME: string;
  FREIGHT_TERMS: string;
  REVERSED_AMOUNT: string;
  DISPUTE_BY_DATE: string;
  INBOUND_SHIPMENT_DELIVERY_ISD_ID: string;
}

export interface ServerShortageResource {
  DUE_DATE: string;
  ID: string;
  INVOICE_AMOUNT: `${number}`;
  INVOICE_CREATE_DATE: string;
  INVOICE_DATE: string;
  INVOICE_ID: string;
  INVOICE_STATUS: string;
  PAID_AMOUNT: string | null;
  PAYEE: string;
  SHORTAGE_AMOUNT: string | null;
  STORE_NAME: string;
  VENDOR: string;
}

export interface ServerAccrualResource {
  AGREEMENT_ID: string;
  AGREEMENT_TITLE: string;
  DISPUTES: ServerAccrualDispute[];
  ID: string;
  INVOICE_DATE: string;
  INVOICE_ID: string;
  INVOICE_PAYMENTS_OSSR?: ServerInvoicePaymentOssr;
  STORE: string;
  TOTAL_AMOUNT_OWED: number;
}

export interface ServerAccrualDispute {
  ID: string;
  ACCRUAL_ID: string;
  AMAZON_DISPUTES: ServerAmazonDispute | null;
  CUSTOMER_INVOICE_STATUS: string | null;
  CONSOLIDATED_DISPUTE_ID: string | null;
  CREATED_AT: string;
  DISPUTE_AMOUNT: string | null;
  DISPUTE_ID: string | null;
}

export interface ServerInvoicePaymentOssr {
  AMOUNTPAID: string;
  DESCRIPTION: string;
  INVOICEAMOUNT: string;
  INVOICECURRENCY: string;
  INVOICEDATE: string;
  INVOICENUMBER: string;
  NATIVE_AMOUNTPAID: string;
  NATIVE_INVOICEAMOUNT: string;
  NATIVE_INVOICEDATE: string;
  NATIVE_REMAININGAMOUNT: string;
  NATIVE_TERMSDISCOUNTTAKEN: string;
  NATIVE_WITHOLDINGAMOUNT: string;
  PAYMENTNUMBER: string;
  REMAININGAMOUNT: string;
  STORE: string;
  TERMSDISCOUNTTAKEN: string;
  VENDOR_NAME: string;
  WITHOLDINGAMOUNT: string;
}

export interface ServerAccrualRemittance {
  UUID: string;
  ACCOUNT_ID: string;
  FILENAME: string;
  MARKETPLACE: string;
  INVOICE_DISPUTED: string;
  RECOVERED_AMOUNT: number;
  REPAID_INVOICE_NUMBER: string;
  PAYMENT_NUMBER: string;
  PAYMENT_DATE: string;
  CURRENCY: string;
  STATUS: string;
  INVOICED_AT: string | null;
  CREATED_AT: string;
  REVERSED_AT: string | null;
}

export type ServerWorkPacketResource = ServerChargebackResource | ServerShortageResource | ServerAccrualResource;

/** Server response for work packet */
export interface ServerWorkPacketBase<R extends ServerWorkPacketResource = ServerWorkPacketResource> {
  ACCOUNT: {
    ACTIVATION_DATE: string;
    ID: number;
    SCOPES: unknown[];
    SHORTAGE_LAG: string;
    STORE_NAME: string;
    TECH_USER: string;
    VC_FILING_USER: string | null;
    VENDOR_ID: string;
    VENDOR: ServerVendor;
  };
  CHANGELOGS: ServerChangeLogEntry[];
  ACCOUNT_ID: number;
  STORE_NAME: string;
  CREATED_AT: string;
  CURRENT_ACTION: string;
  NOTES: string;
  PACKET_ID: string;
  PACKET_OWNER: {
    ID: string;
    EMAIL: string;
    FIRST_SEEN_AT: string;
    LAST_SEEN_AT: string;
    NAME: string;
    NICKNAME: string;
    STATUS: string;
    PROFILE_PIC: string;
  };
  PACKET_STAGE: string;
  RESOURCE_ID: string;
  VALID: boolean;
  RECOVERY_STREAM: ServerStream;
  RESOURCE: R;
  WORK_PACKET_PROPERTIES: (ServerWorkPacketProperty | ServerWorkPacketDisputeIdProperty)[];
}

export interface ServerVendor {
  AGREEMENT_SIGNED_DATE: string;
  BILLING_EMAIL: string | null;
  BILLING_ENTITY: string | null;
  BILLING_NAME: string | null;
  CURRENT_RECOVERY: string;
  ENGAGEMENT_FEE: string | null;
  FINAL_COMMISSION_RATE: string;
  INITIAL_COMMISSION_RATE: string;
  KICK_OFF: string;
  NOTES: string | null;
  PHONE: string | null;
  RECOVERY_THRESHOLD: string;
  REFERRALS: ServerVendorReferral[];
  SPECIFIC_POINT_OF_CONTACT_EMAIL: string;
  SPECIFIC_POINT_OF_CONTACT_NAME: string;
  TIER: VendorTier;
  VENDOR_CREATED: string;
  VENDOR_ID: number;
  VENDOR_NAME: string;
  VENDOR_TYPE: VendorType;
}

export enum VendorType {
  Standard = "Standard",
}

export enum VendorTier {
  SMB = "SMB",
  MidMarket = "Mid Market",
  Enterprise = "Enterprise",
}

export interface ServerVendorReferral {
  COMMISSION_ELIGIBILITY_END_AT: string;
  COMMISSION_RATE: number;
  CUSTOM_COMMISSION_RATE: string | null;
  PARTNER_ID: string;
  PARTNER_TYPE: string;
  PAYMENT_METHOD: string;
  REFERRAL_ID: string;
  REFERRAL_PARTNER_ID: number;
  REFERRAL_PARTNER_NAME: string;
}

export type ServerWorkPacketPropertyKey =
  | "PARENT_INVOICE_NUMBER"
  | "CHILD_INVOICE_NUMBER"
  | "DISPUTED_INVOICE_NUMBER"
  | "EVIDENCE_TYPE"
  | "EVIDENCE_ATTACHMENT"
  | "DISPUTE_AMOUNT"
  | "DISPUTE_ID"
  // accruals:
  | "INVOICE_DATE"
  | "OBAR_OPTION"
  | "SUB_TYPE"
  | "TOTAL_AMOUNT_OWED";

export type ServerWorkPacketPropertyType = "string" | "number" | "date" | "float";

export interface ServerWorkPacketProperty {
  CREATED_AT: string;
  ID: string;
  KEY: ServerWorkPacketPropertyKey;
  TYPE: ServerWorkPacketPropertyType;
  VALUE: string;
  WORK_PACKET_ID: string;
}

export interface ServerAmazonDispute {
  APPROVED_AMOUNT: string;
  DISPUTE_DATE: string;
  DISPUTE_ID: string;
  DISPUTE_REASON: string;
  DISPUTE_STATUS: string;
  DISPUTE_TYPE: string;
  INVOICE_NUMBER: string;
  MARKETPLACE: string;
  STORE_NAME: string;
  TITLE: string;
  TOTAL_DISPUTED_AMOUNT: string;
  VENDOR_NAME: string;
}

export interface ServerPaymentInfo {
  PAID_AMOUNT: string;
  PAYMENT_DATE: string;
  PAYMENT_NUMBER: string;
}

export interface ServerWorkPacketDisputeIdProperty {
  CREATED_AT: string;
  ID: string;
  KEY: "DISPUTE_ID";
  TYPE: "string";
  VALUE: string;
  WORK_PACKET_ID: string;
  AMAZON_DISPUTE: ServerAmazonDispute;
  PAYMENT_INFO: ServerPaymentInfo | null;
}

export type ServerStream = "PQV" | "PPV" | "PRICE_CLAIMS" | "ACCRUAL" | "CHARGEBACKS";

export interface ServerChargebackWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: "CHARGEBACKS";
  RESOURCE: ServerChargebackResource;
}

export interface ServerShortageWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: "PQV" | "PPV";
  RESOURCE: ServerShortageResource;
}

export interface ServerAccrualWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: "ACCRUAL";
  RESOURCE: ServerAccrualResource;
  REMITTANCES?: ServerAccrualRemittance[];
}

export type ServerWorkPacket = ServerChargebackWorkPacket | ServerShortageWorkPacket | ServerAccrualWorkPacket;

export interface WorkPacketServerResponse<T extends ServerWorkPacket> {
  pages: number;
  total_records: number;
  work_packets: T[];
}

export interface WorkPacketTypeMap {
  [WorkPacketType.CHARGEBACKS]: ChargebackWorkPacket;
  [WorkPacketType.SHORTAGES]: ShortageWorkPacket;
  [WorkPacketType.ACCRUALS]: AccrualWorkPacket;
}

export interface ServerWorkPacketTypeMap {
  [WorkPacketType.CHARGEBACKS]: ServerChargebackWorkPacket;
  [WorkPacketType.SHORTAGES]: ServerShortageWorkPacket;
  [WorkPacketType.ACCRUALS]: ServerAccrualWorkPacket;
}

/**
 * Represents a unified summary of work packets that can encompass any of the specific packet summary responses.
 */
export interface WorkPacketsSummaryAggregated {
  totalWorkPackets: number;
  totalStores?: number;
  totalRedFlags?: number;
  recoverableAmount?: {
    total: string;
    pending?: string;
    approved?: string;
  };
  workPackets?: {
    action: WorkPacketActionName;
    amount: number;
  }[];
}

export interface ServerUser {
  EMAIL: string;
  FIRST_SEEN_AT: string;
  ID: string;
  LAST_SEEN_AT: string;
  NAME: string;
  NICKNAME: string;
  PROFILE_PIC: string | null;
}

export type ChangeLogUpdateFieldName = "PACKET_OWNER_ID" | "CURRENT_ACTION" | "NOTES" | "VALID" | "CASE_ID";

export interface ServerChangeLogEntry {
  ACTION: "ASSIGNED" | "IDENTIFIED" | "UPDATED" | "UNASSIGNED" | "LINKED";
  ID: string;
  PACKET_OWNER?: ServerUser;
  RECORDED_AT: string;
  UPDATES: Record<ChangeLogUpdateFieldName, string>;
  USER?: ServerUser;
  WORK_PACKET_ID: string;
  CASE_ID?: string;
}

export interface ChangelogEntry {
  time: string;
  user: string;
  avatarUrl?: string;
  action: string;
  packetId?: string;
  to?: string;
  state?: string;
  nextAction?: string;
  isSystem?: boolean;
}

export interface ChangelogDate {
  date: string;
  entries: ChangelogEntry[];
}

export interface CommandCenterEntry {
  count: number;
  red_flag_count: number;
  total_amount: string | number; // server is known to return whole integers as numbers
  expired_count?: number;
  expired_amount?: string | number; // server is known to return whole integers as numbers
  child?: CommandCenterEntryMap;
}

export type CommandCenterEntryMap = Record<string, CommandCenterEntry>;

export interface CommandCenterResponse {
  data: CommandCenterEntryMap;
  message: string;
  status: number;
}

export interface CommandCenterGridRow {
  hierarchy: string[];
  openCount: number;
  openAmount: string;
  expiredCount?: number;
  expiredAmount?: string;
  redFlags: number;
  id: string;
}

export interface ServerModelAttachment {
  ATTACHMENT_ID: string;
  CREATED_AT: string;
  ID: string;
  MODEL: string;
  MODEL_ID: string;
  ATTACHMENT: {
    ID: string;
    TYPE: string;
    OBJECT_KEY: string;
    FILE_NAME: string;
    CREATED_AT: string;
  };
}

export interface WorkPacketDisputesId {
  client_region: string;
  invoice_ids: string[];
  manual_filing_user: string;
}
