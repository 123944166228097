import {
  type FullStagefulAction,
  StagefulAction,
  StagelessAction,
  StageName,
  stageNames,
  type WorkPacketAction,
  type WorkPacketActionName,
} from "src/types/work-packets";
import { capitalize } from "src/utils/capitalize";
import { WorkPacketType } from "../WorkPacketType";

const actionObjectCaches: Record<WorkPacketType, Map<WorkPacketActionName, WorkPacketAction>> = {
  [WorkPacketType.CHARGEBACKS]: new Map(),
  [WorkPacketType.SHORTAGES]: new Map(),
  [WorkPacketType.ACCRUALS]: new Map(),
};

/*
 * TODO(daniel): consolidate all work packet action definition code under one file.
 *
 * Action definitions are used for three use cases:
 * - Displaying (in table and details popup)
 * - Filtering (in table filter section)
 * - Updating (directly in table, or in details poup)
 */

interface ActionDescriptor {
  category: string;
  title: string;
  color: string;
  onlyAdminCanSetFirstStage?: boolean;
  onlyOnWorkPacketTypes?: WorkPacketType[];
}

const stagefulActionDescriptors: Record<StagefulAction, ActionDescriptor> = {
  [StagefulAction.Identified]: {
    category: "Dispute",
    title: "Identified",
    color: "#FFD878",
    onlyAdminCanSetFirstStage: true,
  },
  [StagefulAction.DisputeFailed]: {
    category: "Dispute",
    title: "Dispute Failed",
    color: "#ff0000",
  },
  [StagefulAction.DisputeCreated]: {
    category: "Dispute",
    title: "Dispute Created",
    color: "#FFCD4D",
  },
  [StagefulAction.DisputeInReview]: {
    category: "Dispute",
    title: "Dispute In Review",
    color: "#FFCD4D",
    onlyOnWorkPacketTypes: [WorkPacketType.CHARGEBACKS, WorkPacketType.ACCRUALS],
  },
  [StagefulAction.DisputeApproved]: {
    category: "Dispute",
    title: "Dispute Resolved - Approved",
    color: "#39D4A5",
  },
  [StagefulAction.DisputePartiallyApproved]: {
    category: "Dispute",
    title: "Dispute Resolved - Partially Approved",
    color: "#318FFF",
  },
  [StagefulAction.DisputeDenied]: {
    category: "Dispute",
    title: "Dispute Resolved - Denied",
    color: "#FF820F",
  },
  [StagefulAction.DisputePaid]: {
    category: "Dispute",
    title: "Dispute - VC Client Payment",
    color: "#7B3DFF",
  },
  [StagefulAction.DisputeInvoiced]: {
    category: "Dispute",
    title: "Dispute - C6 Invoices Client",
    color: "#F155FF",
  },
};

const stagelessActionDescriptors: Record<StagelessAction, ActionDescriptor> = {
  [StagelessAction.Junk]: {
    category: "Other Actions",
    title: "Junk",
    color: "#E03741",
    onlyOnWorkPacketTypes: [WorkPacketType.CHARGEBACKS, WorkPacketType.ACCRUALS],
  },
  [StagelessAction.Duplicate]: {
    category: "Other Actions",
    title: "Duplicate",
    color: "#EAECF0",
  },
  [StagelessAction.Expired]: {
    category: "Other Actions",
    title: "Expired",
    color: "#EAECF0",
    onlyOnWorkPacketTypes: [WorkPacketType.CHARGEBACKS, WorkPacketType.ACCRUALS],
  },
  [StagelessAction.NotApplicable]: {
    category: "Other Actions",
    title: "Not Applicable",
    color: "#EAECF0",
  },
};

export const actionMappers: Record<WorkPacketType, (action: WorkPacketAction) => WorkPacketAction> = {
  [WorkPacketType.CHARGEBACKS]: action => ({
    ...action,
    title: action.title
      .replaceAll("Identified", "Chargeback Identified")
      .replaceAll("Resolved - Denied", "Resolved - Denied/More Info Needed"),
  }),
  [WorkPacketType.SHORTAGES]: action => ({
    ...action,
    title: action.title.replaceAll("Identified", "Shortage Identified").replace(/^First\s/, ""),
  }),
  [WorkPacketType.ACCRUALS]: action => ({
    ...action,
    title: action.title.replaceAll("Identified", "Accrual Identified"),
  }),
};

export const workPacketStages: Record<WorkPacketType, StageName[]> = {
  [WorkPacketType.CHARGEBACKS]: [StageName.First, StageName.Second],
  [WorkPacketType.SHORTAGES]: [StageName.First],
  [WorkPacketType.ACCRUALS]: [StageName.First, StageName.Second, StageName.Third],
};

export const getStagefulActionName = (stage: StageName, action: StagefulAction): FullStagefulAction =>
  `${stage}_${action}`;

function createStagefulActions(workPacketType: WorkPacketType): WorkPacketAction[] {
  const actionNames = Object.entries(stagefulActionDescriptors) as [StagefulAction, ActionDescriptor][];
  const mapper = actionMappers[workPacketType];
  return workPacketStages[workPacketType].flatMap(stageName =>
    actionNames
      .filter(
        ([_actionName, descriptor]) =>
          !descriptor.onlyOnWorkPacketTypes || descriptor.onlyOnWorkPacketTypes.includes(workPacketType),
      )
      .map(([actionName, _descriptor]) => mapper(createStagefulAction(stageName, actionName))),
  );
}

function createStagelessActions(workPacketType: WorkPacketType): WorkPacketAction[] {
  const actionNames = Object.entries(stagelessActionDescriptors) as [StagelessAction, ActionDescriptor][];
  return actionNames
    .filter(
      ([_actionName, descriptor]) =>
        !descriptor.onlyOnWorkPacketTypes || descriptor.onlyOnWorkPacketTypes.includes(workPacketType),
    )
    .map(([actionName, _descriptor]) => createStagelessAction(actionName));
}

export function createActionsForWorkPacketType(workPacketType: WorkPacketType) {
  return [...createStagefulActions(workPacketType), ...createStagelessActions(workPacketType)];
}

const createUnknownAction = (actionName: string): WorkPacketAction => ({
  value: actionName as WorkPacketActionName,
  title: actionName,
  category: "Other Actions",
  color: "#EAECF0",
});

export function createStagefulAction(stageName: StageName, actionName: StagefulAction) {
  const capitalizedStageName = capitalize(stageName);
  const fullActionName = `${stageName}_${actionName}` as const;

  const descriptor = stagefulActionDescriptors[actionName];
  if (!descriptor) return createUnknownAction(fullActionName);

  return {
    value: fullActionName,
    title: `${capitalizedStageName} ${descriptor.title}`,
    category: descriptor.category,
    color: descriptor.color,
    onlyAdminCanSet: descriptor.onlyAdminCanSetFirstStage && stageName === StageName.First,
  };
}

export function createStagelessAction(actionName: StagelessAction) {
  const descriptor = stagelessActionDescriptors[actionName];
  if (!descriptor) createUnknownAction(actionName);

  return {
    value: actionName,
    title: descriptor.title,
    category: descriptor.category,
    color: descriptor.color,
    onlyAdminCanSet: false,
  };
}

function createAction(actionName: string): WorkPacketAction {
  const [stageName, ...rest] = actionName.split("_");
  if (stageNames.includes(stageName as StageName)) {
    return createStagefulAction(stageName as StageName, rest.join("_") as StagefulAction);
  } else {
    return createStagelessAction(actionName as StagelessAction);
  }
}

export function getActionObject(actionName: WorkPacketActionName, workPacketType: WorkPacketType): WorkPacketAction {
  const cache = actionObjectCaches[workPacketType];
  const cached = cache.get(actionName);
  if (cached) return cached;

  const action = actionMappers[workPacketType](createAction(actionName));
  cache.set(actionName, action);
  return action;
}
