import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  CircularProgress,
  Box,
} from "@mui/material";
import { type FC, type SyntheticEvent, useCallback, useMemo, useRef, useState } from "react";
import { Summary } from "./Summary";
import { ChangeLog } from "./ChangeLog";
import { WorkPacketView } from "../WorkPacketView";
import { useWorkPacketsContext } from "../WorkPacketsContext.tsx";
import toast from "react-hot-toast";
import { DialogActionContext, DialogActionStore, PendingDialogAction } from "./DialogActionContext.ts";
import { useQuery } from "@tanstack/react-query";
import { getWorkPacket } from "../queries/work-packets";
import { GenerateObarButton } from "../WorkPacketsGrid/columns/GenerateObarButton";
import { WorkPacketType } from "../WorkPacketType";

interface DetailsPopupProps {
  workPacketId: string;
  open: boolean;
  view: WorkPacketView;
  onClose: () => void;
  onSuccessfulUpdate?: () => void;
}

enum TabIndex {
  Summary,
  ChangeLog,
}

export const WorkPacketDetailsPopup: FC<DetailsPopupProps> = ({
  workPacketId,
  open,
  onClose,
  onSuccessfulUpdate,
}) => {
  const { currentWorkPacketType } = useWorkPacketsContext();
  const [selectedTab, setSelectedTab] = useState<TabIndex>(TabIndex.Summary);
  const [forceLoadingScreen, setForceLoadingSpinner] = useState<boolean>(false);
  const formRef = useRef<any>(null);

  const query = useQuery({
    queryFn: getWorkPacket,
    queryKey: ["work-packets", currentWorkPacketType, "details", workPacketId],
    staleTime: 30000,
  });

  const workPacket = query.data;
  const loading = forceLoadingScreen || query.isFetching;

  const [pendingDialogActions, setPendingDialogActions] = useState<Map<string, PendingDialogAction>>(() => new Map());

  const attemptClose = useCallback(() => {
    if (!pendingDialogActions.size) return void onClose();
    toast.error(
      `Can't close dialog yet - ${Array.from(pendingDialogActions, ([_key, action]) => action.description).join(
        ", ",
      )}`,
    );
  }, [onClose, pendingDialogActions]);

  const setPendingDialogAction = useCallback(
    (key: string, action: PendingDialogAction) => setPendingDialogActions(prev => new Map(prev).set(key, action)),
    [],
  );

  const clearPendingDialogAction = useCallback(
    (key: string) =>
      setPendingDialogActions(prev => {
        const newMap = new Map(prev);
        newMap.delete(key);
        return newMap;
      }),
    [],
  );

  const dialogActionStore: DialogActionStore = useMemo(
    () => ({
      setPendingDialogAction,
      clearPendingDialogAction,
    }),
    [clearPendingDialogAction, setPendingDialogAction],
  );

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: number) => {
      if (!pendingDialogActions.size) return void setSelectedTab(newValue);
      toast.error(
        `Can't change tab - ${Array.from(pendingDialogActions, ([_key, action]) => action.description).join(", ")}`,
      );
    },
    [pendingDialogActions],
  );

  if (!workPacket)
    return (
      <Dialog open={open} onClose={attemptClose} maxWidth="md" fullWidth>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      </Dialog>
    );

  return (
    <DialogActionContext.Provider value={dialogActionStore}>
      <Dialog open={open} onClose={attemptClose} maxWidth="md" fullWidth>
        <DialogTitle>Work Packet Details | {workPacket.recoveryStream}</DialogTitle>
        <DialogContent>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Summary" />
            <Tab label="Change Log" />
            {workPacket.workPacketType === WorkPacketType.ACCRUALS && (
              <GenerateObarButton workPacketId={workPacketId} sx={{ marginLeft: "auto", marginBlock: "0.75rem" }} />
            )}
          </Tabs>

          <Divider />

          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {selectedTab === TabIndex.Summary && (
                <Summary
                  workPacket={workPacket}
                  formRef={formRef}
                  onSubmitSuccess={() => {
                    onClose();
                    onSuccessfulUpdate && onSuccessfulUpdate();
                  }}
                  setLoading={setForceLoadingSpinner}
                />
              )}
              {selectedTab === TabIndex.ChangeLog && (
                <ChangeLog workPacketId={workPacket.packetId} workPacketType={workPacket.workPacketType} />
              )}
            </>
          )}
        </DialogContent>

        {selectedTab === TabIndex.Summary && (
          <DialogActions>
            <Button
              className="contained-reversed"
              variant="contained"
              onClick={attemptClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => formRef.current.submitForm()}
            >
              <span>Save Changes</span>
              {loading && (
                <CircularProgress
                  size={14}
                  style={{ marginLeft: "12px", color: "#101828" }}
                />
              )}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </DialogActionContext.Provider>
  );
};
