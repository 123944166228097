import "./sentry";

import { ConfigCatProvider } from "configcat-react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { configCatOptions, configCatSdkKey } from "./feature-flags";
import "./index.css";

const ENABLE_MOCK_SERVICE_WORKER = false;

if (ENABLE_MOCK_SERVICE_WORKER && import.meta.env.DEV) {
  const { setupWorker } = await import("msw/browser");
  const { handlers } = await import("./mocks/msw-handlers");

  const mockServiceWorker = setupWorker(...handlers);
  await mockServiceWorker.start({ onUnhandledRequest: "bypass" });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ConfigCatProvider sdkKey={configCatSdkKey} options={configCatOptions}>
      <App />
    </ConfigCatProvider>
  </React.StrictMode>,
);
