import TextField from "@mui/material/TextField";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { type FC } from "react";
import { type AccrualWorkPacket } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";

const getField = createFieldFn<AccrualWorkPacket>();

const RemittanceSections: FC<FieldArrayRenderProps & { packet: AccrualWorkPacket }> = ({ packet }) => {
  return (
    <>
      {packet.remittances.map((remittance, index) => {
        const remittanceNumber = index + 1;
        const id = `remittance-${index}`;

        return (
          <FormFieldsGroup withDivider title={`Remittance ${remittanceNumber} Details`} id={id} key={id}>
            <TextField label="Paid Amount" value={remittance.paidAmount} variant="outlined" size="small" disabled />
            <TextField label="Reversal Invoice #" value={remittance.reversalInvoiceNumber} variant="outlined" size="small" disabled />
            <TextField label="Payment ID" value={remittance.paymentId} variant="outlined" size="small" disabled />
            <TextField label="Payment Date" value={remittance.paymentDate} variant="outlined" size="small" disabled />
          </FormFieldsGroup>
        );
      })}
    </>
  );
};

export const AccrualRemittances: FC = () => {
  const { values: packet } = useFormikContext<AccrualWorkPacket>();
  return <FieldArray name={getField("disputes")}>{props => <RemittanceSections
    packet={packet} {...props} />}</FieldArray>;
};
