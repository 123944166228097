import {
  useFeatureFlag as ConfigCat_useFeatureFlag,
  type ConfigCatProvider,
  createConsoleLogger,
  createFlagOverridesFromMap,
  LogLevel,
  OverrideBehaviour,
  User,
} from "configcat-react";
import type { ComponentProps } from "react";
import { attempt } from "./utils/attempt";
import { useAuth0 } from "@auth0/auth0-react";

type ConfigCatOptions = ComponentProps<typeof ConfigCatProvider>["options"];
type FlagValue = string | number | boolean;
type FlagMap = Record<string, FlagValue>;

const LOCAL_STORAGE_FLAG_OVERRIDES_KEY = "cc-flag-overrides";
const URL_FORGET_ALL_FLAG_OVERRIDES_KEY = "cc-forget-all-overrides";

const urlFlagOverrideKeyRegex = /^cc-(.*?)(;str)?$/; // https://configcat.com/docs/sdk-reference/react/#query-string

export const configCatOptions: ConfigCatOptions = {
  logger: createConsoleLogger(LogLevel.Warn),
  flagOverrides: createFlagOverridesFromMap(setupFlagOverrideMap(), OverrideBehaviour.LocalOverRemote),
};

export const configCatSdkKey = import.meta.env.VITE_REACT_APP_CONFIGCAT_SDK_KEY ?? "";

function setupFlagOverrideMap(): FlagMap {
  const url = new URL(window.location.href);
  const params = url.searchParams;

  if (params.has(URL_FORGET_ALL_FLAG_OVERRIDES_KEY)) {
    clearSavedOverrides();
    return {};
  }

  const urlParamFlags: Record<string, FlagValue> = {};

  for (const [key, val] of params) {
    const match = urlFlagOverrideKeyRegex.exec(key);
    if (!match) continue;
    const [, flagKey, typeSuffix] = match;
    const flagValue = getFlagValue(val, typeSuffix === ";str");
    urlParamFlags[flagKey] = flagValue;
  }

  if (Object.keys(urlParamFlags).length === 0) {
    return getSavedOverrides() ?? {};
  }

  const flags = {
    ...getSavedOverrides(),
    ...urlParamFlags,
  };

  updateSavedOverrides(flags);
  return flags;
}

function getFlagValue(input: string, forceString: boolean): FlagValue {
  if (forceString) return input;
  if (input === "true") return true;
  if (input === "false") return false;

  const trimmedInput = input.trim();
  if (trimmedInput === "") return true;

  const parsedNumber = Number.parseFloat(trimmedInput);
  if (Number.isFinite(parsedNumber)) return parsedNumber;
  return input;
}

function getSavedOverrides(): FlagMap | undefined {
  return attempt(
    () => {
      const savedString = window.localStorage.getItem(LOCAL_STORAGE_FLAG_OVERRIDES_KEY);
      if (!savedString) return undefined;
      const parsedValue = JSON.parse(savedString);
      if (!parsedValue || typeof parsedValue !== "object" || Array.isArray(parsedValue)) {
        console.error("Unexpected cc-flag-overrides value", parsedValue);
        return undefined;
      }
      return parsedValue;
    },
    undefined,
    "Error parsing saved cc-flag-overrides",
  );
}

function updateSavedOverrides(flags: FlagMap) {
  window.localStorage.setItem(LOCAL_STORAGE_FLAG_OVERRIDES_KEY, JSON.stringify(flags));
}

function clearSavedOverrides() {
  window.localStorage.removeItem(LOCAL_STORAGE_FLAG_OVERRIDES_KEY);
}

export type LiteralToPrimitive<T extends FlagValue> = T extends boolean ? boolean : T extends number ? number : string;

export interface UseFeatureFlagResult<T extends FlagValue> {
  value: LiteralToPrimitive<T>;
  loading: boolean;
}

export function useFeatureFlag<T extends FlagValue>(flagName: string, defaultValue: T): UseFeatureFlagResult<T> {
  const { user } = useAuth0();
  const userId = user?.sub;

  return ConfigCat_useFeatureFlag(flagName, defaultValue, userId ? new User(userId) : undefined);
}
