import dayjs from "dayjs";
import {
  type AccrualWorkPacket,
  type ChargebackWorkPacket,
  RecoveryStream,
  type ServerAccrualWorkPacket,
  type ServerChangeLogEntry,
  type ServerChargebackWorkPacket,
  type ServerShortageWorkPacket,
  type ServerUser,
  type ServerWorkPacket,
  type ServerWorkPacketDisputeIdProperty,
  type ServerWorkPacketTypeMap,
  type ShortageWorkPacket,
  StagefulAction,
  StageName,
  type WorkPacketActionName,
  type WorkPacketBase,
  type WorkPacketTypeMap,
} from "src/types/work-packets";
import { attempt } from "src/utils/attempt";
import { formatDate, parseDate } from "src/utils/date-parse";
import { WorkPacketType } from "../WorkPacketType";
import { parseAction, type ParsedAction } from "../util/parseAction";

const marketplaceCountryRegex = /(.+) - .+/;
const getMarketplaceCountry = (packet: ServerWorkPacket) =>
  marketplaceCountryRegex.exec(packet.ACCOUNT.STORE_NAME)?.[1] ?? "Unknown";

const mapPacketOwner = (owner?: ServerUser) =>
  owner
    ? {
        id: owner.ID,
        avatar: owner.PROFILE_PIC ?? "",
        title: owner.NICKNAME,
      }
    : {
        id: "",
        avatar: "",
        title: "Unassigned",
      };

/**
 * There are properties that are common to all work packets, but that are computed differently for each type.
 * This type represents all the work packet properties that are computed way the same across all work packet types
 * (by omitting those common types that are computed differently).
 *
 * TODO (daniel): this is internal, but should probably figure out a better name than this
 */
type WorkPacketBaseBase = Omit<
  WorkPacketBase,
  "workPacketType" | "recoveryStream" | "vendorName" | "disputeByDate" | "disputeApprovedAmount"
>;

/** A work packet's dispute is considered resolved if any of its changelog entries is one of these actions.  */
const disputeResolvingActions: ParsedAction["action"][] = [
  StagefulAction.DisputeDenied,
  StagefulAction.DisputeApproved,
  StagefulAction.DisputePartiallyApproved,
];

const findChangeLog = (logs: ServerChangeLogEntry[], predicate: (parsed: ParsedAction) => unknown) =>
  logs.find(log => log.UPDATES.CURRENT_ACTION && predicate(parseAction(log.UPDATES.CURRENT_ACTION)));

const mapServerResponseToWorkPacketBase = (packet: ServerWorkPacket): WorkPacketBaseBase => {
  const latestChangelogEntry: ServerChangeLogEntry | undefined = packet.CHANGELOGS[0];
  const closedAt = findChangeLog(
    packet.CHANGELOGS,
    ({ action }) => action === StagefulAction.DisputeInvoiced,
  )?.RECORDED_AT;

  const evidenceTypeCsv = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "EVIDENCE_TYPE")?.VALUE ?? "";
  const evidenceType = evidenceTypeCsv
    .split(",")
    .map(x => x.trim())
    .filter(x => x.length > 0);

  const evidenceAttachment =
    packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "EVIDENCE_ATTACHMENT")?.VALUE ?? "";

  return {
    createdAt: formatDate(packet.CREATED_AT),
    packetId: packet.PACKET_ID,
    packetStage: packet.PACKET_STAGE,
    storeName: packet.ACCOUNT.STORE_NAME,
    currentPacketOwner: mapPacketOwner(packet.PACKET_OWNER),
    currentAction: packet.CURRENT_ACTION as WorkPacketActionName,
    manualFilingUser: packet.ACCOUNT.VC_FILING_USER ?? "",
    techUser: packet.ACCOUNT.TECH_USER,
    vendorId: packet.ACCOUNT.VENDOR_ID,
    storeId: packet.ACCOUNT.ID,
    packetDate: parseDate(packet.CREATED_AT),
    recoveryStreamServer: packet.RECOVERY_STREAM,
    recoveryStreamActivationDate: parseDate(packet.ACCOUNT.ACTIVATION_DATE),
    vcFilingUser: packet.ACCOUNT.VC_FILING_USER ?? "",
    notes: packet.NOTES,
    isValidWorkPacket: packet.VALID,
    marketplaceCountry: getMarketplaceCountry(packet),
    modifiedAt: latestChangelogEntry ? formatDate(latestChangelogEntry.RECORDED_AT) : "",
    lastModifiedBy: mapPacketOwner(latestChangelogEntry?.USER),
    closedDate: closedAt ? formatDate(closedAt) : "",
    evidenceType,
    evidenceAttachment,

    // fake columns
    _details: undefined,
  };
};

export const mapServerResponseToChargebackWorkPacket = (packet: ServerChargebackWorkPacket): ChargebackWorkPacket => {
  const disputeIdProperty = packet.WORK_PACKET_PROPERTIES.find(
    (property): property is ServerWorkPacketDisputeIdProperty => property.KEY === "DISPUTE_ID",
  );
  const amazonDispute = disputeIdProperty?.AMAZON_DISPUTE;

  const firstDisputeCreatedAt = findChangeLog(
    packet.CHANGELOGS,
    action => "stage" in action && action.stage === StageName.First && action.action === StagefulAction.DisputeCreated,
  )?.RECORDED_AT;

  const secondDisputeCreatedAt = findChangeLog(
    packet.CHANGELOGS,
    action => "stage" in action && action.stage === StageName.Second && action.action === StagefulAction.DisputeCreated,
  )?.RECORDED_AT;

  return {
    ...mapServerResponseToWorkPacketBase(packet),
    workPacketType: WorkPacketType.CHARGEBACKS,
    recoveryStream: RecoveryStream.Chargebacks,
    vendorName: packet.RESOURCE.VENDOR_NAME,
    disputeByDate: packet.RESOURCE.DISPUTE_BY_DATE ? formatDate(packet.RESOURCE.DISPUTE_BY_DATE) : undefined,
    calculatedDisputeByDate: attempt(
      () => dayjs(packet.RESOURCE.CREATE_DATE).add(30, "days"),
      undefined,
      "Error calculating chargeback packet dispute by date",
    ),
    recoveryStreamSubtype: packet.RESOURCE.RECOVERY_STREAM_SUB_TYPE,
    vcPoId: packet.RESOURCE.VC_PO_ID,
    asinId: packet.RESOURCE.ASIN_ID,
    chargebackIssueId: packet.RESOURCE.CHARGEBACK_ISSUE_ID,
    recoveryStreamSubtype1: packet.RESOURCE.ISSUE_SUB_TYPE_DESC,
    recoveryStreamSubtype2: packet.RESOURCE.NOTES,
    financialCharge: packet.RESOURCE.FINANCIAL_CHARGE,
    reversedAmount: packet.RESOURCE.REVERSED_AMOUNT,
    firstDisputeCreatedAt: firstDisputeCreatedAt && formatDate(firstDisputeCreatedAt),
    secondDisputeCreatedAt: secondDisputeCreatedAt && formatDate(secondDisputeCreatedAt),
    chargebackCreateDate: formatDate(packet.RESOURCE.CREATE_DATE),
    vendorCode: packet.RESOURCE.VENDOR_CODE,
    disputeApprovedAmount: amazonDispute?.APPROVED_AMOUNT ?? "",
    freightTerms: packet.RESOURCE.FREIGHT_TERMS,
    inboundShipmentDeliveryIsdId: packet.RESOURCE.INBOUND_SHIPMENT_DELIVERY_ISD_ID,
  };
};

const parentInvoiceIdFromChildInvoiceIdRegex = /(.*?)(SCR)*/;
const getVcParentInvoiceId = (packet: ServerShortageWorkPacket): string | undefined => {
  const parentInvoiceProperty = packet.WORK_PACKET_PROPERTIES.find(
    property => property.KEY === "PARENT_INVOICE_NUMBER",
  );
  if (parentInvoiceProperty) return parentInvoiceProperty.VALUE;

  const childInvoiceProperty = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "CHILD_INVOICE_NUMBER");
  if (childInvoiceProperty) return parentInvoiceIdFromChildInvoiceIdRegex.exec(childInvoiceProperty.VALUE)?.[1];
};

const parseShortageLag = (serverShortageLag: string | null): number => {
  if (!serverShortageLag || !serverShortageLag.trim()) return 0;
  const parsedNumber = Number.parseInt(serverShortageLag);
  if (!Number.isInteger(parsedNumber)) return 0;
  return parsedNumber;
};

export const mapServerResponseToShortageWorkPacket = (packet: ServerShortageWorkPacket): ShortageWorkPacket => {
  const disputeResolvedAt = findChangeLog(packet.CHANGELOGS, ({ action }) =>
    disputeResolvingActions.includes(action),
  )?.RECORDED_AT;

  const disputeIdProperty = packet.WORK_PACKET_PROPERTIES.find(
    (property): property is ServerWorkPacketDisputeIdProperty => property.KEY === "DISPUTE_ID",
  );
  const amazonDispute = disputeIdProperty?.AMAZON_DISPUTE;
  const paymentInfo = disputeIdProperty?.PAYMENT_INFO;

  const disputeAmountProperty = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "DISPUTE_AMOUNT");

  const shortageLag = parseShortageLag(packet.ACCOUNT.SHORTAGE_LAG);
  const disputeByDate = attempt(
    () => dayjs(packet.RESOURCE.DUE_DATE).add(shortageLag, "days").format("YYYY-MM-DD"),
    "",
    "Error calculating shortage packet dispute by date",
  );

  const vcDisputedInvoiceId =
    packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "DISPUTED_INVOICE_NUMBER")?.VALUE ?? "";

  return {
    ...mapServerResponseToWorkPacketBase(packet),
    workPacketType: WorkPacketType.SHORTAGES,
    disputeByDate,
    recoveryStream: RecoveryStream.InventoryShortages,
    vendorName: packet.RESOURCE.VENDOR,
    disputeId: disputeIdProperty?.VALUE ?? "",
    disputeAmount: disputeAmountProperty?.VALUE ?? "",
    disputeCreatedAt: disputeIdProperty?.CREATED_AT ? formatDate(disputeIdProperty?.CREATED_AT) : "",
    invoiceDate: formatDate(packet.RESOURCE.INVOICE_DATE),
    invoiceDueDate: formatDate(packet.RESOURCE.DUE_DATE),
    vcParentInvoiceId: getVcParentInvoiceId(packet) ?? "",
    vcDisputedInvoiceId,
    vcPayeeCode: packet.RESOURCE.PAYEE,
    shortageLag: packet.ACCOUNT.SHORTAGE_LAG,
    disputeApprovedAmount: amazonDispute?.APPROVED_AMOUNT ?? "",
    disputeResolvedAt: disputeResolvedAt ?? "",
    disputePaymentId: paymentInfo?.PAYMENT_NUMBER ?? "",
    disputePaymentAmount: paymentInfo?.PAID_AMOUNT ?? "",
    disputePaymentDate: paymentInfo?.PAYMENT_DATE ? formatDate(paymentInfo.PAYMENT_DATE) : "",
  };
};

export const mapServerResponseToAccrualWorkPacket = (packet: ServerAccrualWorkPacket): AccrualWorkPacket => {
  const subType = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "SUB_TYPE")?.VALUE ?? "";
  const obarOption = packet.WORK_PACKET_PROPERTIES.find(property => property.KEY === "OBAR_OPTION")?.VALUE ?? "";
  const remittance = packet.RESOURCE.INVOICE_PAYMENTS_OSSR;
  const currency = remittance?.INVOICECURRENCY ?? "USD";
  const currencyFormatter = new Intl.NumberFormat(undefined, {
    currency,
    style: "currency",
    currencyDisplay: "symbol",
    currencySign: "accounting",
    signDisplay: "never",
  });

  return {
    ...mapServerResponseToWorkPacketBase(packet),
    workPacketType: WorkPacketType.ACCRUALS,
    recoveryStream: RecoveryStream.Accruals,
    vendorName: packet.ACCOUNT.VENDOR.VENDOR_NAME,
    disputeByDate: "",
    disputeApprovedAmount: "",
    obarOption,
    disputes: packet.RESOURCE.DISPUTES.map(dispute => {
      const disputeAmount =
        dispute.DISPUTE_AMOUNT !== null ? currencyFormatter.format(dispute.DISPUTE_AMOUNT as `${number}`) : "";

      const disputeDate = formatDate(dispute.CREATED_AT);

      const approvedAmount =
        dispute.AMAZON_DISPUTES?.APPROVED_AMOUNT != null
          ? currencyFormatter.format(dispute.AMAZON_DISPUTES?.APPROVED_AMOUNT as `${number}`)
          : "";

      return {
        id: dispute.ID,
        disputeAmount,
        disputeDate,
        originalDisputeId: dispute.DISPUTE_ID ?? "",
        consolidatedDisputeId: dispute.CONSOLIDATED_DISPUTE_ID ?? "",
        disputeStatus: dispute.AMAZON_DISPUTES?.DISPUTE_STATUS ?? "",
        invoiceStatus: dispute.CUSTOMER_INVOICE_STATUS ?? "",
        approvedAmount,

        // dispute resolution - work in progress
        disputeResolutionDate: "",
        paidAmount: "",
        reversalInvoiceNumber: "",
      };
    }),
    remittances: (packet.REMITTANCES || []).map(r => {
      return {
        paidAmount: currencyFormatter.format(r.RECOVERED_AMOUNT),
        reversalInvoiceNumber: r.REPAID_INVOICE_NUMBER,
        paymentId: r.PAYMENT_NUMBER,
        paymentDate: r.PAYMENT_DATE,
      };
    }),
    subType,
    agreementId: packet.RESOURCE.AGREEMENT_ID,
    invoiceId: packet.RESOURCE.INVOICE_ID,
    invoiceDate: formatDate(packet.RESOURCE.INVOICE_DATE),
    currency,
    invoiceAmount: remittance ? currencyFormatter.format(remittance.INVOICEAMOUNT as `${number}`) : "",
    obarSuggestedDisputeAmount: currencyFormatter.format(packet.RESOURCE.TOTAL_AMOUNT_OWED),
    sumOfAllReversalPayments: "",
    recoveryRate: "",
  };
};

export function mapServerToLocalWorkPackets<T extends WorkPacketType>(
  workPacketType: T,
  serverItems: ServerWorkPacketTypeMap[T][],
): WorkPacketTypeMap[T][];

export function mapServerToLocalWorkPackets(workPacketType: WorkPacketType, serverItems: ServerWorkPacket[]) {
  if (workPacketType === WorkPacketType.CHARGEBACKS) {
    return (serverItems as ServerChargebackWorkPacket[]).map(mapServerResponseToChargebackWorkPacket);
  }
  if (workPacketType === WorkPacketType.SHORTAGES) {
    return (serverItems as ServerShortageWorkPacket[]).map(mapServerResponseToShortageWorkPacket);
  }
  if (workPacketType === WorkPacketType.ACCRUALS) {
    return (serverItems as ServerAccrualWorkPacket[]).map(mapServerResponseToAccrualWorkPacket);
  }
}
